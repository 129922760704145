@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Josefin Sans',
        sans-serif;
    font-family: 'Poppins',
        sans-serif;
    font-family: 'PT Serif',
        serif;
    font-family: 'Roboto',
        sans-serif;
}